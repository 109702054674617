import SlotTimetable from './index'
import timestamp from 'utils/firebaseTimestamp'

const SlotTimetableConverter = {
  toFirestore: (slotTimetable) => {
    const { id, booking, ...fs } = slotTimetable
    console.log(slotTimetable)
    return { ...fs, booking: booking?.toJSON ? booking?.toJSON() : booking, ...timestamp(id) }
  },
  fromFirestore: (snapshot, options) => {
    const slotTimetable = snapshot.data(options)
    return new SlotTimetable(
      slotTimetable.name,
      slotTimetable.type,
      slotTimetable.isPrivate,
      slotTimetable.start.toDate(),
      slotTimetable.end.toDate(),
      slotTimetable.timeId,
      slotTimetable.slotId,
      slotTimetable.booking,
      snapshot.id
    )
  },
}
export default SlotTimetableConverter
