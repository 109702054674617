import Setting from 'models/Setting'

const getObject = (setting) =>
  new Setting(
    setting.colors,
    setting.hideBookedSlots,
    setting.approx,
    setting.autoConfirmBookings,
    setting.autoConfirmMinutes,
    setting.autoCancelBookings,
    setting.autoCancelMinutes,
    setting.countDownText,
    setting.sameDayBooking,
    setting.bookingCloseTime === '' ? null : setting.bookingCloseTime,
    setting.advancedBookingDays,
    setting.editableDays,
    setting.welcome,
    setting.sms,
    setting.email
  )

export default getObject
