import React, { createContext, useContext, useState, useMemo } from 'react'
import Loading from 'components/Loading'

export const LoadingContext = createContext({})

export const LoadingProvider = ({ children, startLoading = true }) => {
  const [loading, setLoading] = useState(startLoading)

  const value = useMemo(() => {
    return {
      loading,
      setLoading,
    }
  }, [loading, setLoading])

  return (
    <LoadingContext.Provider value={value}>
      <Loading />
      {children}
    </LoadingContext.Provider>
  )
}

export const useLoading = () => {
  const { loading, setLoading } = useContext(LoadingContext)

  return [loading, setLoading]
}
