import React, { forwardRef } from 'react'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { Select, MenuItem, FormControl, InputLabel, FormHelperText } from '@material-ui/core'
// core components
import styles from 'assets/jss/material-dashboard-react/components/customInputStyle.js'

const useStyles = makeStyles(styles)

export default forwardRef(
  (
    {
      fullWidth,
      error,
      helperText,
      label,
      options = [],
      optionValue = 'value',
      optionLabel = 'label',
      formControlStyle,
      variant = 'filled',
      blankItem = false,
      required,
      ...props
    },
    ref
  ) => {
    const classes = useStyles()

    return (
      <FormControl className={classes.formControl} fullWidth={fullWidth} ref={ref} style={formControlStyle}>
        {label && (
          <InputLabel
            classes={{ root: classes.labelRoot, asterisk: classes.labelAsterisk }}
            variant={variant}
            error={!!error}
            required={required}
          >
            {label}
          </InputLabel>
        )}
        <Select variant={variant} {...props} error={!!error} classes={{ disabled: classes.disabled }}>
          {blankItem && <MenuItem value=""></MenuItem>}
          {options.map((option) => (
            <MenuItem key={option[optionValue]} value={option[optionValue]}>
              {option[optionLabel]}
            </MenuItem>
          ))}
        </Select>
        {(helperText || error) && <FormHelperText error>{helperText || error.message}</FormHelperText>}
      </FormControl>
    )
  }
)
