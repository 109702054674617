import React, { forwardRef, memo } from 'react'
import DatePicker from './input'
import Calendar from './calendar'

export default memo(
  forwardRef(({ calendar, ...props }, ref) => {
    if (calendar) {
      return <Calendar {...props} ref={ref} />
    }
    return <DatePicker {...props} ref={ref} />
  })
)
