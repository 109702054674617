import { makeStyles } from '@material-ui/core/styles'
import chroma from 'chroma-js'
const borderColor = '#6c6c6c'

const useSlotStyles = (colors) =>
  makeStyles((theme) => {
    const styles = {}
    Object.keys(colors).forEach((key) => {
      styles[key] = {
        backgroundColor: colors[key],
        color: theme.palette.getContrastText(colors[key]),
        borderColor,
        '&.selected': {
          backgroundColor: chroma(colors[key]).darken().hex(),
        },
        '&.current': {
          backgroundColor: chroma(colors[key]).darken(2).hex(),
        },
        '&.current.selected': {
          backgroundColor: chroma(colors[key]).darken(2.5).hex(),
        },
      }
    })
    return styles
  })

export default useSlotStyles
