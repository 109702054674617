import { serverTimestamp } from 'firebase/firestore/lite'

const firebaseTimestamp = (id) => {
  const timestamp = serverTimestamp()

  if (id) {
    return { updatedAt: timestamp }
  }
  return { updatedAt: timestamp, createdAt: timestamp }
}
export default firebaseTimestamp
