import Setting from './index'
import timestamp from 'utils/firebaseTimestamp'

const SettingConverter = {
  toFirestore: (setting) => {
    const { id, ...fs } = setting
    return { ...fs, ...timestamp(id) }
  },
  fromFirestore: (snapshot, options) => {
    const setting = snapshot.data(options)
    return new Setting(
      setting.colors,
      setting.hideBookedSlots,
      setting.approx,
      setting.autoConfirmBookings,
      setting.autoConfirmMinutes,
      setting.autoCancelBookings,
      setting.autoCancelMinutes,
      setting.countDownText,
      setting.sameDayBooking,
      setting.bookingCloseTime?.toDate(),
      setting.advancedBookingDays,
      setting.editableDays,
      setting.welcome,
      setting.sms,
      setting.email,
      snapshot.id
    )
  },
}

export default SettingConverter
