export default [
  {
    name: 'KBZ Pay',
    id: 'kbzpay',
  },
  {
    name: 'Octoverse',
    id: 'octoverse',
    methods: [
      {
        name: 'MPU',
        id: 'MPU_LCARD',
      },
      {
        name: 'CB Pay',
        id: 'CBPAY_PIN',
      },
    ],
  },
  {
    name: 'MPU',
    id: 'mpu',
  },
  {
    name: 'MPU',
    id: 'MPU_LCARD',
  }, 
  {
    name: 'CBPay',
    id: 'CBPAY_PIN',
  },
  {
    name: 'AYAPay',
    id: 'ayapay',
  },
  {
    name: 'MPU',
    id: 'paymal',
  },
  {
    name: 'Paypal',
    id: 'paypal',
  },
  {
    name: 'Stripe',
    id: 'stripe',
  },
]
