import { useEffect, useState } from 'react'
import { useLoading } from 'hooks/useLoading'

const useApi = (api, watch = [], defaults = []) => {
  const [data, setData] = useState(defaults)
  const [, setLoading] = useLoading()
  let cleanup = false

  useEffect(() => {
    if (!cleanup && typeof api === 'function') {
      setLoading(true)
      api()
        .then((d) => {
          setData(d)
        })
        .catch((e) => {
          console.error(e.message)
          console.error(e.stack)
        })
        .finally(() => {
          setLoading(false)
        })
    }

    return () => {
      cleanup = true
    }
  }, watch)

  return data
}

export default useApi
