import React, { memo, useImperativeHandle, useState, forwardRef } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'

const ConfirmDialog = memo(
  forwardRef(({ title, text, confirmButtonText = 'Confirm', cancelButtonText = 'Cancel', onConfirm, onClose }, ref) => {
    const [open, setOpen] = useState(false)
    const [params, setParams] = useState(null)

    useImperativeHandle(ref, () => ({
      open: (params) => {
        setParams(params)
        setOpen(true)
      },
      close: () => onClose(),
    }))

    onClose = onClose ?? (() => setOpen(false))

    return (
      <Dialog
        open={!!open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose(params)} color="primary">
            {cancelButtonText}
          </Button>
          <Button onClick={() => onConfirm(params)} color="primary" autoFocus>
            {confirmButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    )
  })
)
export default ConfirmDialog
