import getCollection from './getCollection'
import { where, query, getDocs } from 'firebase/firestore/lite'

const queryData = async (days, includePrivate, slotId) => {
  const [startDay, endDay] = days
  let q = query(getCollection(), where('start', '>=', startDay), where('start', '<=', endDay))

  if (!includePrivate) {
    q = query(q, where('isPrivate', '==', includePrivate))
  }

  if (slotId) {
    q = query(q, where('slotId', '==', slotId))
  }

  return getDocs(q)
}

export default queryData
