import React, { memo } from 'react'
import { Backdrop, CircularProgress } from '@material-ui/core'
import { useLoading } from 'hooks/useLoading'
import useStyles from './styles'
import Progress from 'components/Progress'

export default memo(() => {
  const classes = useStyles()
  const [loading] = useLoading()

  return (
    <>
      <Progress loading={loading} />
      {loading && (
        <Backdrop className={classes.backdrop} open={loading} style={{ zIndex: 2000 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </>
  )
})
