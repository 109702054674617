export const getTypeLabel = (type) => {
  return types.find((t) => t.value === type)?.label
}

export const TYPE_OPEN = '0'
export const TYPE_CLOSE = '1'

export const types = [
  { value: TYPE_OPEN, label: 'Open' },
  { value: TYPE_CLOSE, label: 'Close' },
]

export const visibility = [
  { value: 0, label: 'Public' },
  { value: 1, label: 'Private' },
]
