import React from 'react'
// @material-ui/core components
import Grid from '@material-ui/core/Grid'

const GridItem = ({ children, ...rest }) => (
  <Grid item {...rest}>
    {children}
  </Grid>
)

export default GridItem
