import React, { forwardRef, memo } from 'react'
import CustomInput from 'components/CustomInput'
import { lightFormat, parseISO, isValid } from 'date-fns'
import { dateFormat } from 'constants/dateTimeFormat'

export default memo(
  forwardRef(({ format = dateFormat, InputLabelProps, value, onChange, ...props }, ref) => (
    <CustomInput
      type="date"
      {...props}
      InputLabelProps={{
        shrink: true,
        ...InputLabelProps,
      }}
      value={value && isValid(value) ? lightFormat(value, format) : value}
      onChange={(e) => onChange(parseISO(`${e.target.value}T00:00:00.000Z`))}
    />
  ))
)
