import { documentId } from 'firebase/firestore/lite'
import getCurrentUser from 'actions/login/getCurrentUser'
import { assignSlotPermission } from 'constants/permissions'
import getAll from 'actions/base/getAll'
import getCollection from './getCollection'

const getAllSlots = async (props = {}) => {
  const currentUser = getCurrentUser()

  if (currentUser) {
    const {
      claims: { slotIds, permissions },
    } = await currentUser.getIdTokenResult()

    if (permissions && !permissions.includes(assignSlotPermission)) {
      let { where = [] } = props
      where.push([documentId(), 'in', slotIds])
      props.where = where
    }
  }
  return getAll(props, getCollection)
}

export default getAllSlots
