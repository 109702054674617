import Booking from './index'
import timestamp from 'utils/firebaseTimestamp'

const BookingConverter = {
  toFirestore: (booking) => {
    const { id, slot, ...fs } = booking.toJSON ? booking.toJSON() : booking
    return { ...fs, slot: slot?.toReferenceJSON ? slot?.toReferenceJSON() : slot, ...timestamp(id) }
  },
  fromFirestore: (snapshot, options) => {
    const booking = snapshot.data(options)
    return new Booking(
      booking.name,
      booking.email,
      booking.phone,
      booking.remark,
      booking.meta,
      booking.start?.toDate(),
      booking.end?.toDate(),
      booking.status,
      booking.slotTimetableId,
      booking.userId,
      booking.slot,
      booking.fee,
      booking.paidAmount,
      booking.createdAt?.toDate(),
      booking.deletedAt?.toDate(),
      snapshot.id
    )
  },
}

export default BookingConverter
