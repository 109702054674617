import React, { forwardRef, memo } from 'react'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import useStyles from './styles'

export default memo(
  forwardRef(({ onChange, value, calendarType = 'US', ...props }, ref) => {
    const classes = useStyles()
    return (
      <Calendar
        {...props}
        ref={ref}
        calendarType={calendarType}
        onChange={onChange}
        value={value}
        className={classes.calendar}
      />
    )
  })
)
