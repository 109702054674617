import getCollection from './getCollection'
import Booking from 'models/Booking'
import getObject from './getObject'
import { doc, setDoc } from 'firebase/firestore/lite'

const updatField = async (id, booking, value) => {
  booking.set(value)
  return setDoc(doc(getCollection(), id), booking instanceof Booking ? booking : getObject(booking), { merge: true })
}

export default updatField
