import getFirestore from 'actions/firebase/firestore'
import { collection } from 'firebase/firestore/lite'

const getCollection = (colName, converter = null) => {
  const col = collection(getFirestore(), colName)

  if (converter) {
    return col.withConverter(converter)
  }

  return col
}

export default getCollection
