import yup from 'utils/yup'
import { TYPE_CLOSE } from 'constants/times'
import { bookingStatuses, statusBooked, statusNotAvailable, statusAvailable } from 'constants/bookings'
import displayTime from 'utils/displayTime'
import { TIMEZONE } from 'config/app'
import BaseModel from 'models/BaseModel'

const borderColor = '#6c6c6c'

class SlotTimetableModel extends BaseModel {
  constructor(
    name = '',
    type = 0,
    isPrivate = false,
    start = null,
    end = null,
    timeId = null,
    slotId = null,
    booking = null,
    id = null
  ) {
    super()
    this.name = name
    this.type = type
    this.isPrivate = isPrivate
    this.start = start
    this.end = end
    this.timeId = timeId
    this.slotId = slotId
    this.booking = booking
    this.id = id
  }

  isBookable() {
    return this.type !== TYPE_CLOSE && this.booking === null
  }

  getType(isPublic = false) {
    if (this.type == TYPE_CLOSE) {
      return statusNotAvailable
    } else if (this.booking !== null) {
      return isPublic ? statusBooked : bookingStatuses.find((s) => s.value === this.booking.status)
    }
    return statusAvailable
  }

  getColor(colors, isPublic = false) {
    if (this.type == TYPE_CLOSE) {
      return {
        backgroundColor: colors.notAvailable,
        borderColor,
      }
    } else if (this.booking !== null) {
      return {
        backgroundColor: isPublic ? colors[statusBooked] : colors[this.booking.status],
        borderColor,
      }
    } else {
      return {
        backgroundColor: colors.available,
        borderColor,
      }
    }
  }

  displayTime(timeonly = false) {
    return displayTime(this.start, this.end, TIMEZONE, timeonly)
  }

  bookingStatus() {
    return bookingStatuses.find((s) => s.value === this.booking?.status)?.label
  }

  getTitle(showBooking) {
    return showBooking && this.booking !== null ? `${this.booking?.name} (${this.bookingStatus()})` : this.name
  }

  static validationSchema = yup.object({
    name: yup.string().label('Name').required(),
    phone: yup.date().nullable().label('Phone Number').required(),
    remark: yup.date().label('Remark').required(),
  })
}
export default SlotTimetableModel
