import { getTime } from 'date-fns'

const betweenTimetable = (timetables, timetable) => {
  const start = getTime(timetable.start)
  return timetables.find((t) => start >= getTime(t.start) && start < getTime(t.end))
}

const prepareSlotTimetables = async (slotTimetables) => {
  const preparedTimetables = []
  slotTimetables.forEach((timetables) => {
    timetables.forEach((timetable) => {
      if (!betweenTimetable(preparedTimetables, timetable)) {
        preparedTimetables.push(timetable)
      }
    })
  })

  return preparedTimetables
}

export default prepareSlotTimetables
