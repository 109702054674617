import { SLOT_LABEL, FEE_LABEL, GROUP_LABEL } from 'config/app'

export const assignSlotPermission = 'assign-slots'

const permissions = [
  { value: 'slots', label: SLOT_LABEL },
  { value: assignSlotPermission, label: 'Assign Slots' },
  { value: 'times', label: 'Times' },
  { value: 'bookings', label: 'Bookings' },
  { value: 'fees', label: FEE_LABEL },
  { value: 'payments', label: 'Payments' },
  { value: 'reminders', label: 'Reminders' },
  { value: 'groups', label: 'Groups' },
  { value: 'templates', label: 'Templates' },
  { value: 'transactions', label: 'Transactions' },
  { value: 'users', label: 'Users' },
  { value: 'roles', label: 'Roles & Permissions' },
  { value: 'settings', label: 'Settings' },
]

export const groupPermissions = [
  {
    label: `${SLOT_LABEL}s / ${GROUP_LABEL}s / ${FEE_LABEL}s`,
    value: ['slots', 'bookings', 'fees', 'groups'],
  },
  { label: `Bookings Times`, value: ['times'] },
  { label: `Payments / Transactions`, value: ['payments', 'transactions'] },
  { label: `Assign user to ${SLOT_LABEL.toLowerCase()}`, value: [assignSlotPermission] },
  { label: 'Reminders / Templates', value: ['reminders', 'templates'] },
  { label: 'Users / Roles & Permissions', value: ['users', 'roles'] },
  { label: 'Templates / Settings', value: ['templates', 'settings'] },
]

export default permissions
