import { format, isValid, addMinutes, subMinutes } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'

const displayTime = (start, end, timezone, timeonly = false, approx = {}, showTimezone = false) => {
  if (isValid(start) && isValid(end)) {
    const timeOnlyFormat = 'hh:mm a'
    const timezoneFormat = 'z'

    return `${
      timeonly
        ? `${formatInTimeZone(start, timezone, timeOnlyFormat)}`
        : `${formatInTimeZone(start, timezone, 'dd-MM-yyyy')} [${formatInTimeZone(
            approx?.enabled ? subMinutes(start, approx.minutes) : start, timezone,
            timeOnlyFormat
          )} - ${formatInTimeZone(approx?.enabled ? addMinutes(end, approx?.minutes) : end, timezone, timeOnlyFormat)}]`
    }${showTimezone ? ` ${format(start, timezoneFormat)}` : ''}`
  }

  return ''
}

export default displayTime
