import getAllRaw from './getAllRaw'

const getAll = async (props = {}, getCollection, softDeletable = true) =>
  getAllRaw(props, getCollection, softDeletable).then((snapshot) => {
    const { pageInfo = {} } = props
    const { limit: pageLimit } = pageInfo
    const { docs } = snapshot
    const lastItem = docs.length > pageLimit ? docs.pop() : null
    return { data: docs.map((d) => d.data()), lastItem }
  })

export default getAll
