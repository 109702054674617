import { getAllSlots } from 'actions/slot'
import { startOfDay, endOfDay } from 'date-fns'
import queryData from './queryData'
import filterSlotTimetables from './filterSlotTimetables'
import prepareSlotTimetables from './prepareSlotTimetables'

const prepareTimetable = async (timetables, slots) =>
  Promise.all(
    slots.map(async (slot) => {
      const slotTimetables = await Promise.all(
        slot.times.map(async (timeId) => filterSlotTimetables(slot.id, timeId, timetables))
      )

      return prepareSlotTimetables(slotTimetables)
    })
  )

const getAllSlotTimetables = async ({ day = new Date(), isPrivate = false }) => {
  const { docs } = await queryData([startOfDay(day), endOfDay(day)], isPrivate)

  if (!docs.empty) {
    const timetables = docs.map((d) => d.data())
    const timeIds = timetables.map((t) => t.timeId)
    const uniqueTimeIds = [...new Set(timeIds)]
    const { data: slots } = await getAllSlots(uniqueTimeIds, isPrivate)

    const preparedTimetables = await prepareTimetable(timetables, slots)
    return {
      timetables: preparedTimetables.flat(),
      slots,
    }
  }

  return { timetables: [], slots: [] }
}

export default getAllSlotTimetables
