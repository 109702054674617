import React from 'react'
import { SnackbarProvider as Provider } from 'notistack'
import Grow from '@material-ui/core/Grow'

export const SnackbarProvider = ({
  children,
  maxSnack = 3,
  anchorOrigin = { vertical: 'top', horizontal: 'center' },
  TransitionComponent = Grow,
}) => (
  <Provider maxSnack={maxSnack} anchorOrigin={anchorOrigin} TransitionComponent={TransitionComponent}>
    {children}
  </Provider>
)
