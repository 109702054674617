import getFirebaseApp from './index'
import { companyId } from 'config/app'
import { getFirestore, collection, doc, connectFirestoreEmulator } from 'firebase/firestore/lite'

let db
const firestore = (dbOnly = false) => {
  if (!db) {
    db = getFirestore(getFirebaseApp())

    if (location.hostname === 'localhost') {
      connectFirestoreEmulator(db, 'localhost', 4000)
    }
  }

  return dbOnly ? db : doc(collection(db, 'companies'), companyId)
}

export default firestore
