import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

const NormalLogo = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, height: 60)
        }
      }
    }
  `)

  return <GatsbyImage image={data.logo.childImageSharp.gatsbyImageData} alt="Logo" />
}

export default NormalLogo
