import getAllRaw from 'actions/base/getAllRaw'
import getCollection from './getCollection'
import { documentId } from 'firebase/firestore/lite'
import { TYPE_OPEN } from 'constants/times'

const getAvailableSlotTimetable = async (id) =>
  getAllRaw(
    {
      where: [
        [documentId(), '==', id],
        ['type', '==', TYPE_OPEN],
        ['booking', '==', null],
      ],
    },
    getCollection,
    false
  )

export default getAvailableSlotTimetable
