export const statusBooked = { value: 'booked', label: 'Booked' }
export const statusConfirmed = { value: 'confirmed', label: 'Confirmed' }
export const statusNotAvailable = { value: 'notAvailable', label: 'Not Available' }
export const statusAvailable = { value: 'available', label: 'Available' }
export const statusCancelled = { value: 'cancelled', label: 'Cancelled' }

export const defaultStatus = statusBooked

export const bookingStatuses = [
  statusBooked,
  statusConfirmed,
  { value: 'noShow', label: 'No Show' },
  { value: 'noStatus', label: 'No Status' },
  statusCancelled,
]

export const publicStatuses = [statusBooked, statusAvailable]
export const editBookingStatuses = [statusBooked, statusConfirmed, statusCancelled]

export const publicEditableBookingStatuses = [statusBooked]
