import Slot from './index'
import timestamp from 'utils/firebaseTimestamp'

const SlotConverter = {
  toFirestore: (slot) => {
    const { id, name, description, group, fees, ...fs } = slot
    return {
      ...fs,
      search: `${name.toLowerCase()} ${description?.toLowerCase()} ${group?.name?.toLowerCase()}`,
      name,
      description,
      group: group?.toReferenceJSON !== undefined ? group?.toReferenceJSON() : group,
      fees: fees.map((f) => (f.toReferenceJSON !== undefined ? f.toReferenceJSON() : f)),
      ...timestamp(id),
    }
  },
  fromFirestore: (snapshot, options) => {
    const slot = snapshot.data(options)
    return new Slot(
      slot.name,
      slot.description,
      slot.isPrivate,
      slot.group,
      slot.fees,
      slot.times,
      slot.deletedAt?.toDate(),
      snapshot.id
    )
  },
}

export default SlotConverter
