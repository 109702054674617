import { getSlot } from 'actions/slot'
import { startOfDay, endOfDay, max } from 'date-fns'
import queryData from './queryData'
import filterSlotTimetables from './filterSlotTimetables'
import prepareSlotTimetables from './prepareSlotTimetables'

const getAllSlotTimetablesBySlotId = async ({ slotId, day = new Date(), isPrivate = false }) => {
  const { docs } = await queryData([max([startOfDay(day), new Date()]), endOfDay(day)], isPrivate, slotId)

  if (!docs.empty) {
    const slot = await getSlot(slotId)
    const timetables = docs.map((d) => d.data())

    const slotTimetables = await Promise.all(
      slot.times.map(async (timeId) => filterSlotTimetables(slotId, timeId, timetables))
    )

    return [await prepareSlotTimetables(slotTimetables)]
  }
  return []
}

export default getAllSlotTimetablesBySlotId
