import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  calendar: {
    marginBottom: theme.spacing(1.5),
  },
  time: {
    border: '1px solid #ddd',
    borderRadius: 4,
    padding: theme.spacing(1.4, 1.2),
    textAlign: 'center',
    marginBottom: 10,
    cursor: 'pointer',
    height: '100%',
    lineHeight: '18px',
  },
  indicatorContainer: {
    display: 'inline-block',
    marginTop: 10,
    marginRight: 10,
  },
  indicator: {
    display: 'inline-block',
    width: 20,
    height: 20,
    verticalAlign: 'middle',
  },
  emptyTimes: {
    textAlign: 'center',
    width: '100%',
    height: 250,
    display: 'table',
    '&>div': {
      display: 'table-cell',
      verticalAlign: 'middle',
    }
  },
}))
