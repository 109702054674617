import { getSlotTimetableRef, getAvailableSlotTimetable } from 'actions/slotTimetable'
import getCollection from './getCollection'
import getFirestore from 'actions/firebase/firestore'
import { runTransaction, doc, getDoc } from 'firebase/firestore/lite'

const changeBooking = async (booking, { id: slotTimetableId }) =>
  runTransaction(getFirestore(true), async (transaction) => {
    const bookingRef = doc(getCollection(), booking.id)

    const slotTimetable = await getAvailableSlotTimetable(slotTimetableId)
    if (slotTimetable.empty) {
      throw Error("Booking wasn't available!")
    }

    const slotTimetableData = slotTimetable.docs.pop().data()

    await transaction.update(getSlotTimetableRef(slotTimetableId), { booking: booking.toReferenceJSON() })
    booking.slotTimetableId = slotTimetableId
    booking.start = slotTimetableData.start
    booking.end = slotTimetableData.end
    await transaction.set(bookingRef, booking, { merge: true })

    return getDoc(bookingRef).then(async (d) => ({ id: d.id, ...d.data() }))
  })

export default changeBooking
