import getFirestore from 'actions/firebase/firestore'

const getRootDoc = (converter = null) => {
  const doc = getFirestore()

  if (converter) {
    return doc.withConverter(converter)
  }

  return doc
}

export default getRootDoc
