import React from 'react'
// @material-ui/core components
import Grid from '@material-ui/core/Grid'

const GridContainer = ({ children, spacing = 2, ...rest }) => (
  <Grid container {...rest} spacing={spacing}>
    {children}
  </Grid>
)

export default GridContainer
