import yup from 'utils/yup'
import BaseModel from 'models/BaseModel'

class SlotModel extends BaseModel {
  constructor(
    name = '',
    description = '',
    isPrivate = false,
    group = null,
    fees = [],
    times = [],
    deletedAt = null,
    id = null
  ) {
    super()
    this.name = name
    this.description = description
    this.isPrivate = isPrivate
    this.group = group
    this.fees = fees
    this.times = times
    this.deletedAt = deletedAt
    this.alias = group ? `${group.name} @ ${name}` : name
    this.id = id
  }

  toReferenceJSON() {
    return {
      name: this.name,
      id: this.id,
    }
  }

  static validationSchema = yup.object({
    name: yup.string().label('Name').required(),
    times: yup.array().label('Times').min(1, 'Times is required'),
  })
}
export default SlotModel
